import React from "react";
import Heading from '../components/Heading'

// Split email into parts to avoid simple scraping - need to test if this helps
const emailUser = 'hello'
const emailDomain = 'meir.pro'

const ContactBody = () => {
  return (
    <div className="prose prose-lg text-center">
      <Heading>Contact</Heading>

      <p className="mb-4">
        Feel free to reach out to me at{' '}
        <a
          href={`mailto:${emailUser}@${emailDomain}`}
          className="text-blue-600 hover:text-blue-800"
          onClick={(e) => {
            e.preventDefault()
            window.location.href = `mailto:${emailUser}@${emailDomain}`
          }}
        >
          {emailUser} [at] {emailDomain}
        </a>
      </p>

      <p className="mb-4">
        I'll do my best to respond within a reasonable timeframe.
      </p>
    </div>
  )
}

export { ContactBody }

const Contact = () => {
  return (
    <div className="flex flex-col items-center justify-center px-4 py-8 max-w-screen-md mx-auto">
      <ContactBody />
    </div>
  )
}

export default Contact;
