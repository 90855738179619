import React, { useEffect, useRef } from 'react'
import {
  Code,
  Server,
  Terminal,
  Flag,
  Cpu,
  Database,
  GitBranch,
  ArrowUpRight,
  ChevronRight,
  Download,
  ClipboardList,
  BarChart,
} from 'lucide-react'
import { ContactBody } from './Contact'

const SKILLS = {
  programming: {
    icon: Code,
    title: 'Programming Languages',
    items: [
      'HTML/CSS',
      'JavaScript (ES6+)',
      'Node.js',
      'PHP',
      'Python',
      'React',
      'TypeScript',
    ],
  },
  frameworks: {
    icon: Server,
    title: 'Frameworks & Libraries',
    items: [
      'Express JS',
      'GraphQL',
      'JWT Authentication',
      'Next.js',
      'Socket.IO',
      'Tailwind CSS',
      'WebRTC',
      '2FA/OTP',
      'REST',
      'API Integration',
    ],
  },
  systems: {
    icon: Terminal,
    title: 'IT & Systems',
    items: [
      'LAN/WAN Networking',
      'Firewall Configuration',
      'Windows OS Optimization',
      'MacOS Optimization',
      'PowerShell/CMD/Terminal',
      'Docker',
      'Cloud Platform Fundamentals',
      'Virtual Machine Configuration',
    ],
  },
  hardware: {
    icon: Cpu,
    title: 'Hardware & Automation',
    items: [
      'Automation Scripting',
      'Embedded Systems',
      'IoT Devices',
      'Payment System Integration',
      'VR Arcade Machine Components',
    ],
  },
  database: {
    icon: Database,
    title: 'Database Technologies',
    items: ['MongoDB', 'MySQL', 'PostgreSQL'],
  },
  versionControl: {
    icon: GitBranch,
    title: 'Version Control',
    items: ['GitHub Workflows', 'GitHub', 'GitLab'],
  },
  //   projectManagement: {
  //     icon: ClipboardList,
  //     title: 'Project Management',
  //     items: ['Jira', 'Confluence', 'Agile', 'Kanban'],
  //   },
  dataVisualization: {
    icon: BarChart,
    title: 'Data Visualization',
    items: ['Google Sheets'],
  },
  languages: {
    icon: Flag,
    title: 'Languages',
    items: [
      'English (Native)',
      'Hebrew (Native)',
      'Yiddish (Basic)',
      'French/Chinese/Japanese (Elementary)',
    ],
  },
}

const ProjectLink = ({ title, subtitle, text, url, className = '' }) => {
  const target = '_blank'
  const rel = 'noopener'
  return (
    <>
      <div className="flex items-center ">
        <h3 className="font-bold mr-1">{title}</h3>
        <ChevronRight className="mr-1" size={14} />
        <a
          href={url}
          target={target}
          rel={rel}
          className={`text-mantis-600 text-sm flex items-center hover:text-mantis-700 hover:translate-x-1 transition-all duration-200 ${className}`}
        >
          <span className="mr-1">{text}</span>
          <ArrowUpRight size={14} />
        </a>
      </div>
      <p className="text-gray-500">{subtitle}</p>
    </>
  )
}

const Resume = () => {
  const targetRef = useRef()

  useEffect(() => {
    document.title = 'Meir Knapp - Resume'
  }, [])
  // Add useEffect for print event listeners
  useEffect(() => {
    const originalStyle = document.body.style.cssText
    const handleBeforePrint = () => {
      // Store current body style
      // Hide all other elements
      document.body.style.cssText = `
        visibility: hidden;
        position: relative;
      `

      // Make resume visible with added margin for printing
      targetRef.current.style.visibility = 'visible'
      targetRef.current.style.position = 'absolute'
      targetRef.current.style.left = '0'
      targetRef.current.style.top = '0'
      targetRef.current.style.margin = '0'
      targetRef.current.style.padding = '0 0'
    }

    const handleAfterPrint = () => {
      // Restore original styles
      document.body.style.cssText = originalStyle
      targetRef.current.style.position = ''
      targetRef.current.style.left = ''
      targetRef.current.style.top = ''
      targetRef.current.style.margin = ''
    }

    window.addEventListener('beforeprint', handleBeforePrint)
    window.addEventListener('afterprint', handleAfterPrint)

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint)
      window.removeEventListener('afterprint', handleAfterPrint)
    }
  }, [])

  const handlePrint = () => {
    window.print()
  }

  return (
    <>
      <div className="flex flex-col items-center">
        {/* relative content-center items-center */}
        {/* <div className="mb-4 mx-auto"> */}
        <button
          onClick={handlePrint}
          className="bg-mantis-600 hover:bg-mantis-700 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center"
        >
          <Download className="mr-2" size={20} />
          Print/Save Resume
        </button>
        {/* </div> */}

        <div className="self-start lg:content-center scrollbar-hide w-screen overflow-auto">
          <div
            ref={targetRef}
            id="resume"
            //width 150vw (on phones only) - 1/3 goes to sidebar, 2/3 goes to content, leaving content with 100vw
            className="w-[150vw] md:w-[1000px] flex bg-white border print:border-none print:shadow-none my-8 rounded-lg print:rounded-none"
          >
            {/* Skills Sidebar */}
            <div className="w-1/3 bg-mantis-600 text-white p-6 print:bg-mantis-600 rounded-l-lg print:rounded-none print:text-white print:[-webkit-print-color-adjust:exact] print:[print-color-adjust:exact] print:h-[100vh]">
              <h2 className="text-2xl font-bold mb-6 border-b pb-3">
                Technical Expertise
              </h2>

              {/* Replace the skills section with: */}
              {Object.values(SKILLS).map(({ icon: Icon, title, items }) => (
                <div key={title} className="mb-6">
                  <h3 className="font-semibold text-lg mb-2 flex items-center justify-between transition-all duration-200 hover:translate-x-2 hover:text-opacity-80">
                    <span className="mr-2">{title}</span>
                    <Icon className="mr-2" size={20} />
                  </h3>
                  <ul className=" text-sm list-disc flex flex-wrap ">
                    {items.map((item) => (
                      <li key={item} className="ml-6">
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            {/* Main Content */}
            <div className="w-2/3 md:min-w-min p-8">
              <header className="mb-6 items-center flex flex-col">
                <h1 className="text-3xl text-gray-800 font-lucky tracking-wider">
                  Meir Knapp
                </h1>
                <div className="flex flex-wrap gap-4 text-gray-600 items-center justify-evenly my-4">
                  <div className="flex items-center">
                    <span className="mr-1 select-none">📍</span>
                    <span>
                      Location: <span className="underline">NY</span>
                    </span>
                  </div>
                  <span className="select-none">|</span>
                  <div className="flex items-center">
                    <span className="mr-1 select-none">📧</span>
                    <a
                      href="mailto:hello@meir.pro"
                      target="_blank"
                      rel="noreferrer"
                      className="underline hover:text-mantis-600 transition-colors"
                    >
                      hello@meir.pro
                    </a>
                  </div>
                  <span className="select-none">|</span>
                  <div className="flex items-center">
                    <span className="mr-1 select-none">🌐</span>
                    <a
                      href="https://meir.pro"
                      className="underline hover:text-mantis-600 transition-colors"
                    >
                      meir.pro
                    </a>
                  </div>
                  <span className="select-none">|</span>
                  <div className="flex items-center">
                    <span className="mr-1 select-none">💼</span>
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      href="https://www.linkedin.com/in/meir-k/"
                      className="underline hover:text-mantis-600 transition-colors"
                    >
                      LinkedIn
                    </a>
                  </div>
                </div>
                <p className="text-gray-600 items-center flex flex-wrap">
                  Technology Leader | Full-Stack Developer | Systems Engineer
                </p>
              </header>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                  Professional Summary
                </h2>
                <p className="text-gray-700">
                  Technology professional with proven expertise in integrating
                  hardware and software systems, specializing in VR technology
                  and systems infrastructure. Developed streamlined
                  technological solutions that improved operational efficiency,
                  reduced deployment times, and enhanced cross-team
                  communication through strategic technical implementations.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                  Professional Experience
                </h2>
                <div>
                  <h3 className="font-bold text-lg">CTO | VR 360 Action</h3>
                  <h2 className="text-gray-500">2018 - Dec 2024</h2>
                  <p className="py-4">
                    Personally contributed to the design of games, including
                    systems optimizing communication between hardware and
                    software. Installed games in 45+ locations, each featuring
                    multiple machines, including training staff and providing
                    technical support. Managed 5+ developers locally and
                    offshore, collaborating with 20+ customer support personnel
                    from different teams, to ensure clients get the best and
                    fastest resolutions.
                  </p>
                  <ul className="list-disc list-inside text-gray-700 text-sm space-y-2 mb-4">
                    <li>
                      <strong>Systems Optimization:</strong> Developed a
                      customized Windows OS installation package that
                      significantly reduced machine setup time by 80-90%. This
                      enabled faster team deployment and standardized machine
                      configurations across the organization.
                    </li>
                    <li>
                      <strong>Communication Infrastructure:</strong> Designed
                      and implemented a comprehensive API protocol, facilitating
                      seamless communication between multiple developer teams.
                      This established a unified information exchange system,
                      enhancing project coordination and reducing communication
                      overhead.
                    </li>
                    <li>
                      <strong>Backend Infrastructure:</strong> Established
                      centralized backend servers for file management and
                      analytics, creating a robust and scalable system.
                    </li>
                    <li>
                      <strong>Network Architecture:</strong> Implemented
                      internal LAN networks and external firewall
                      configurations, ensuring robust security protocols and
                      seamless network connectivity throughout the
                      organizational infrastructure.
                    </li>
                    <li>
                      <strong>Technical Support:</strong> Managed comprehensive
                      IT infrastructure, providing rapid resolution for network,
                      printer, and WiFi challenges. This minimized operational
                      disruptions and enhanced overall system reliability.
                    </li>
                    <li>
                      <strong>Technical Design:</strong> Engineered VR arcade
                      machine systems, developing critical communication
                      protocols between game components, hardware interfaces,
                      and software launchers.
                    </li>
                    <li>
                      <strong>Technical Support & IT Management: </strong>
                      Provided rapid resolution for network and hardware
                      challenges, minimizing downtime and maintaining
                      operational reliability.
                    </li>
                  </ul>
                  <span className="pt-8">
                    Promoted from Technician/Engineer.
                  </span>
                </div>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                  Education
                </h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-bold text-lg">
                      Practical Electrical Engineering
                    </h3>
                    <p className="text-gray-500">
                      2-Year Professional Degree | 2015-2017
                    </p>
                    <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
                      <li>
                        Graduated with honors - Top 5 in class (95th percentile
                        nationally)
                      </li>
                      <li>Focus on practical applications and system design</li>
                    </ul>
                  </div>

                  <div>
                    <h3 className="font-bold text-lg">
                      Network Administration & Computer Hardware
                    </h3>
                    <p className="text-gray-500">
                      2-Year Technical Program | 2013-2015
                    </p>
                    <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
                      <li>Microsoft MCSA Certification</li>
                      <li>
                        Comprehensive study of network infrastructure and
                        hardware systems
                      </li>
                    </ul>
                  </div>
                  {/* 
                  <div>
                    <h3 className="font-bold text-lg">High School Diploma</h3>
                    <ul className="list-disc list-inside text-gray-700 text-sm space-y-1">
                      <li>Advanced Placement (AP) equivalent in Mathematics</li>
                      <li>Focus on STEM subjects</li>
                    </ul>
                  </div> */}
                  <p className="text-gray-700">
                    Always learning and never stopping my pursuit of knowledge.
                  </p>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-semibold mb-3 border-b pb-2">
                  Website Projects
                </h2>
                <div className="space-y-2">
                  <ProjectLink
                    title="Personal Website"
                    subtitle="My personal website, always evolving with new features and updates as I find the time."
                    text="meir.pro"
                    url="https://meir.pro"
                  />
                  <ProjectLink
                    title="Knapp Cartoons"
                    subtitle="Your go-to for the finest caricature artwork, crafted by talented artists who bring your vision to life."
                    text="knappcartoons.com"
                    url="https://knappcartoons.com"
                  />
                </div>
              </section>
            </div>
          </div>
        </div>

        <div className="mt-20">
          <ContactBody />
        </div>
      </div>
    </>
  )
}

export default Resume
