import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";
import GoogleAnalytics from "./utils/GoogleAnalytics";
import DOMCleanup from "./utils/DOMCleanup";
// import PageSetup from "./utils/PageSetup.js";

// import router from "./routes";
// import { RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import OfflineMessage from "./parts/offlineMessage";
import { Nav } from "./parts/Nav/Nav";
import { Route, Router, Switch } from 'wouter'

// import "./i18n.js";

// eslint-disable-next-line no-unused-vars
// import { BrowserRouter } from "react-router-dom";
import Footer from './parts/footer.js'
import TechnicalInformation from './pages/SharePage/TechnicalInformation.js'
import WIPExperiments from './pages/WIPExperiments.js'
import OnlineTools from './pages/OnlineTools.js'
import Resume from './pages/Resume.js'
import About from './pages/About.js'
import Contact from './pages/Contact.js'
import App from './App.js'
// import TechnicalInformation from "./pages/SharePage/TechnicalInformation";
// import MainSharePage from "./pages/SharePage/MainSharePage";
// import LoadingPage from "./parts/LoadingPage/loading";

// const root = ReactDOM.createRoot(document.body);
const root = ReactDOM.createRoot(document.getElementById('root'))

const isProduction = process.env.NODE_ENV === 'production'

root.render(
  <React.StrictMode>
    <Router>
      <DOMCleanup />
      <Nav />
      <div className="max-w-screen-lg mx-auto min-h-[80vh] flex flex-col justify-center">
        <Switch>
          {/* <Route path="/share" component={SharePage} nest /> */}
          <Route path="/data" component={TechnicalInformation} />
          <Route path="/wip" component={WIPExperiments} />
          <Route path="/tools" component={OnlineTools} />
          <Route path="/resume" component={Resume} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/" component={App} />

          <Route>
            {(params) => {
              const path = params['*'].split(/[?#]/)[0]
              return (
                <center>
                  <b>404:</b> The page <code>"/{path}"</code> isn't ready yet!
                </center>
              )
            }}
          </Route>
        </Switch>
      </div>
      <Footer />

      {isProduction ? <GoogleAnalytics /> : null}
      {isProduction ? <Analytics /> : null}
      {isProduction ? <SpeedInsights /> : null}
      <OfflineMessage />
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
