import React, { useEffect, useState } from "react";
import { useLocation } from "wouter";

const useQuery = () => {
  return new URLSearchParams(window.location.search);
};

const FromChecker = () => {
  // eslint-disable-next-line no-unused-vars
  const [location, setLocation] = useLocation();
  const query = useQuery();

  const [source, setSource] = useState("");
  useEffect(() => {
    const src = query.get("s");

    if (src) {
      setSource(src);
      console.log(`Traffic source: ${src}`);
      // Send this data to Google Analytics
      if (window.gtag !== undefined)
        window.gtag("event", "page_view", {
          traffic_source: src,
        });
      else console.error("no window.gtag found");
      // Remove the 'from' parameter from the URL
      query.delete("from");
      const newSearch = query.toString();
      const newLocation = `${window.location.pathname}${
        newSearch ? `?${newSearch}` : ""
      }`;
      setLocation(newLocation, { replace: true });
    }
  }, [query, setLocation]);

  return <>{source ? `Thanks for Checking out ${source}` : ""} </>;
};
export default FromChecker;
