import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'wouter'
import { useLocalStorage } from '@uidotdev/usehooks'

const projects = [
  {
    id: 1,
    title: 'Soul Art from Safed',
    date: '2015',
    description:
      'Based on the Holy Hebrew letters. Inspired from the teachings of Chassidut and Kabbalah. Letters of Light, Letters of Love',
    technologies: ['PHP', 'HTML', 'JavaScript', 'CSS', 'SVG', 'AJAX'],
    primaryLink: 'https://ruthknapp.com',
    screenshot: '/assets/projects/ruthknapp.com-optimised.jpeg',
    longImage: '/assets/projects/ruthknapp.com-long.webp',
    referrerLink: 'RuthKnapp',
  },
  {
    id: 2,
    title: 'Knapp Cartooning Studio',
    date: '2024-09',
    description: "Custom caricature portraits by the Knapp artists'",
    technologies: [
      'React JS',
      'Tailwind CSS',
      'Responsive UI',
      'SVG Graphics',
      'WebP Images',
      'Loading Optimization',
      'Mobile First',
      'SEO',
    ],
    primaryLink: 'https://knappcartoons.com',
    screenshot: '/assets/projects/knappcartoons.com-optimised.jpeg',
    longImage: '/assets/projects/knappcartoons.com-long.webp',
    referrerLink: 'KnappCartoons',
  },
  {
    id: 3,
    title: 'Jerusalem Clay',
    date: '2014-01',
    // description: "A brief description of what this project does",
    technologies: ['PHP', 'HTML', 'JavaScript', 'CSS', 'SVG', 'AJAX'],
    primaryLink: 'https://Jerusalem.meir.pro',
    screenshot: '/assets/projects/jerusalem.meir.pro-optimised.jpeg',
    // longImage: "/assets/projects/jerusalemclay-long.webp",
    referrerLink: 'JerusalemClay',
  },
  {
    id: 4,
    title: 'Backend for VR 360 Action',
    date: '2020-01',
    description:
      'Users can view the machines tracking info filterd by location or individual machines, all info is updated live using websockets.',
    technologies: ['Node JS', 'WebSocket', 'JWT', 'MongoDB', 'Analytics'],
    primaryLink: 'https://vip.vr360action.com',
    screenshot: '/assets/projects/vip.vr360action.com-optimised.jpeg',
    longImage: '/assets/projects/vip.vr360action.com-long.webp',
    referrerLink: 'VR360Action',
    note: 'This website requires authentication. A demo account may be available in the future.',
  },
  //   {
  //     id: 3,
  //     title: "Project Name 1",
  //     description: "A brief description of what this project does",
  //     technologies: ["React", "Node.js", "MongoDB"],
  //     primaryLink: "https://t.me/anifinder_bot",
  //     // backupLink: false,
  //     screenshot: "/images/knappcartoons-screenshot.png",
  //   },
  // Add more projects as needed
].sort((a, b) => new Date(b.date) - new Date(a.date))

export const ExistingWork = () => {
  const [location] = useLocation()
  const referrer = new URLSearchParams(location.split('?')[1]).get('s')
  const [visitedProjects, setVisitedProjects] = useLocalStorage(
    'visitedProjects',
    []
  )

  // Add referrer to visited projects if it matches a project
  useEffect(() => {
    if (referrer) {
      const referredProject = projects.find((p) => p.referrerLink === referrer)
      if (referredProject && !visitedProjects.includes(referredProject.id)) {
        setVisitedProjects([...visitedProjects, referredProject.id])
      }
    }
  }, [referrer, visitedProjects, setVisitedProjects])

  const [clickedImageId, setClickedImageId] = useState(null)
  const [hoveredImageId, setHoveredImageId] = useState(null)
  const scrollContainerRefs = useRef({})
  const isUserScrolling = useRef(false)

  useEffect(() => {
    if (clickedImageId) return
    const container = scrollContainerRefs.current[hoveredImageId]
    if (!container) return

    let startTime
    let animationFrameId
    const duration = 5000

    const handleUserScroll = () => {
      isUserScrolling.current = true
      // Cancel animation
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
      // Remove scroll listener after it's triggered
      container.removeEventListener('wheel', handleUserScroll)
      container.removeEventListener('touchmove', handleUserScroll)
    }

    // Add scroll listeners
    container.addEventListener('wheel', handleUserScroll)
    container.addEventListener('touchmove', handleUserScroll)

    const animate = (currentTime) => {
      if (isUserScrolling.current) return

      if (!startTime) startTime = currentTime
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1)

      const totalScroll = container.scrollHeight - container.clientHeight
      container.scrollTop = totalScroll * progress

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animate)
      }
    }

    animationFrameId = requestAnimationFrame(animate)

    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
      container.scrollTop = 0
      isUserScrolling.current = false
    }
  }, [hoveredImageId, clickedImageId])

  const handleVisitSite = (url, projectTitle, projectId, isBackup = false) => {
    // Add project to visited list if not already there
    if (!visitedProjects.includes(projectId)) {
      setVisitedProjects([...visitedProjects, projectId])
    }

    console.log(
      `Visited ${projectTitle} - ${isBackup ? 'backup' : 'primary'} link`
    )
    window.open(url, '_blank')
  }

  return (
    <div className="p-8 mx-auto">
      <h1>My Projects</h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
        {projects.map((project) => {
          const highlight =
            referrer === project.referrerLink ||
            visitedProjects.includes(project.id)

          return (
            <div
              key={project.id}
              onClick={() => {
                setClickedImageId(
                  clickedImageId === project.id ? null : project.id
                )
              }}
              onMouseEnter={() => setHoveredImageId(project.id)}
              onMouseLeave={() => {
                setClickedImageId(null)
                setHoveredImageId(null)
                isUserScrolling.current = false
              }}
              onTouchStart={() => {
                // setClickedImageId(
                //   clickedImageId === project.id ? null : project.id
                // )
                setHoveredImageId(project.id)
              }}
              onTouchEnd={() => {
                // setHoveredImageId(null)
                setClickedImageId(null)
                isUserScrolling.current = false
              }}
              className={`bg-white rounded-lg py-6 shadow-sm flex flex-col justify-between h-full relative transition-all duration-300 ease-in-out ${
                highlight ? 'border-green-400 ' : ''
              } border-2 hover:border-green-500 hover:z-30  z-20`}
            >
              <div className="px-6">
                <h2 className="font-mono text-lg">{project.title}</h2>

                <p className="text-sm text-slate-700 ">{project.description}</p>

                <div className="flex flex-wrap gap-2 my-4">
                  {project.technologies.map((tech, index) => (
                    <span
                      key={index}
                      className="bg-slate-200 px-2 py-1 rounded text-sm"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>

              <div className="">
                <div className="flex flex-col items-center z-10 relative sm:px-6">
                  <div
                    onClick={() => {
                      setClickedImageId(
                        clickedImageId === project.id ? null : project.id
                      )
                    }}
                    onMouseLeave={() => {
                      // setHoveredImageId(null)
                      setClickedImageId(null)
                      isUserScrolling.current = false
                    }}
                    ref={(el) => (scrollContainerRefs.current[project.id] = el)}
                    className={`w-full sm:rounded-lg overflow-hidden hover:overflow-y-scroll scrollbar-hide max-h-[250px] transition-all duration-300 ${
                      clickedImageId ? 'scale-100' : 'sm:hover:scale-150 '
                      // hover:border-green-500 hover:border-y-2 sm:hover:border-2
                    }`}
                  >
                    <img
                      src={
                        project.longImage
                          ? project.longImage
                          : project.screenshot
                      }
                      alt={project.title}
                      draggable={false}
                      className={`w-full select-none touch-none pointer-events-none object-cover object-top`}
                    />
                  </div>
                </div>
                {project.note && (
                  <div className=" bg-gray-200 p-2 rounded text-sm opacity-50 transition-opacity duration-200 group-hover:opacity-100">
                    {project.note}
                  </div>
                )}

                <div className="flex gap-4 mt-4 justify-between items-center z-0 group px-6">
                  {highlight ? (
                    <div className="bg-green-500 text-white py-2 px-4 rounded-md font-medium">
                      Thanks for checking out my work! 🎉
                    </div>
                  ) : (
                    <div></div>
                  )}

                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a
                    href={project.primaryLink}
                    onClick={(e) => {
                      e.preventDefault()
                      handleVisitSite(
                        project.primaryLink,
                        project.title,
                        project.id
                      )
                    }}
                    target="_blank"
                    rel="noopener"
                    className="relative text-green-500 border border-green-500 hover:bg-green-400 rounded-md px-4 py-2 cursor-pointer flex items-center transition-all duration-200  hover:text-white"
                  >
                    {highlight ? 'Visit Again' : 'Visit Site'}
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
