import React, { useState, useEffect } from "react";

const OfflineMessage = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsVisible(!navigator.onLine);
    };

    // Add online/offline event listeners
    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    // Check online status on component mount
    handleOnlineStatus();

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="bg-red-300 text-center p-3 fixed bottom-0 w-full z-50 text-white flex items-center justify-center">
        <div className="text-center px-8 text-white flex items-center justify-center">
          <p className="">
            You are offline!
            <br />
            This site doesn't do anything special in offline mode (yet!).
            <br />
            You can close this message OR go online to see it disappear.
          </p>
        </div>
        <button className="fixed right-4 text-3xl" onClick={handleClose}>
          &times;
        </button>
      </div>
    )
  );
};
export default OfflineMessage;
