import React from "react";
// import meirImage from "../assets/images/MeirByDesk.png";
import styled from "styled-components";
import Heading from '../components/Heading'

const StyledImage = styled.img`
  width: 100%;
  max-width: 768px;
  height: auto;
  object-fit: cover;
`

const About = () => {
  const meirImage = './assets/about/Meir-at-desk.webp'
  return (
    <div className="flex flex-col items-center p-8 max-w-screen-md mx-auto font-normal">
      <div className="prose prose-lg space-y-6 ">
        <Heading>About Me</Heading>

        <p className="mb-8">Hello! I'm Meir.</p>

        <p className="mb-8 leading-relaxed ">
          You can find me on{' '}
          <a
            href="https://www.linkedin.com/in/meir-k/"
            className="text-blue-600 hover:text-blue-800"
            target="_blank"
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          , check out my{' '}
          <a href="/resume" className="text-blue-600 hover:text-blue-800">
            resume
          </a>
          , or{' '}
          <a href="/contact" className="text-blue-600 hover:text-blue-800">
            contact page
          </a>{' '}
          for more details about my professional experience.
        </p>

        <p className="mb-8 leading-relaxed ">
          I'm passionate about: gaining new knowladge 📚, sipping hot mint tea
          🫖 (coffee-free zone here! ☕️), and cracking challenging puzzles 🧩.
          <br />
          When I'm not at my desk working, you might find me learning something
          new 📚, experimenting with new recipes in the kitchen 🍳, tinkering
          with hardware projects 🔧, listening to podcasts on Spotify 🎧, or
          getting lost in strategy board games ♟️.
        </p>

        <StyledImage
          src={meirImage}
          alt="Meir at desk"
          className="mb-8 pointer-events-none"
          width={2140}
          height={1222}
        />

        <h2 className="text-2xl font-bold mt-12 mb-6">What I Do</h2>
        <p className="mb-8 leading-relaxed pb-8">
          I enjoy building things that live on the internet, from simple
          websites to complex applications. I'm particularly interested in
          creating tools that make people's lives easier 🛠️.
        </p>

        <h2 className="text-2xl font-bold mt-12 mb-6">Fun Facts</h2>
        <ul className="list-disc list-inside mb-8 space-y-2">
          <li>I speak multiple programming languages and human languages 🗣️</li>
          <li>I more than once debugged code in my sleep (literally!) 💤</li>
          {/* <li>I organize my room better than my bookmarks 📚</li> */}
          <li>I'm always trying to learn something new 🎯</li>
          <li>
            I like Minecraft, mostly building with redstone in creative mode 🎮
          </li>
        </ul>
      </div>
    </div>
  )
}

export default About;
