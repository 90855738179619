import React from 'react'
import AccordionList from '../components/AccordionList'
import Heading from '../components/Heading'

//can add a hidden field, only if url has #hidden or something, show it

const OnlineTools = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const secretKey = urlParams.get('secret')
  const quickShare = urlParams.get('share')

  const items = [
    {
      title: 'drop.lol',
      description:
        'Drop.lol is a peer-to-peer file sharing service that allows users to share files directly between browsers without the need for a server. It supports file sharing with end-to-end encryption and is designed for quick and easy transfers, making it ideal for any use.',
      tags: ['File Sharing', 'Chat', 'Peer-to-Peer', 'Encryption'],
      link: `https://drop.lol/${quickShare ? quickShare : ''}`,
      open: quickShare ? true : false,
      hasStar: true,
    },
    {
      title: 'wormhole.app',
      description:
        'Wormhole allows you to share files with end-to-end encryption and a link that automatically expires. You can send files up to 10 GB easily, making it a secure and convenient option for file sharing.',
      tags: ['File Sharing', 'Encryption', 'Secure Sharing', 'Temporary Links'],
      link: 'https://wormhole.app/',
    },
    {
      title: 'Snapdrop.net',
      description: 'A peer-to-peer file sharing application.',
      link: 'https://snapdrop.net',
      tags: ['File Sharing', 'WebRTC'],
    },
    {
      title: 'T.LY',
      description:
        'T.LY is a URL shortening service that allows you to create short links quickly and easily. It offers features like link tracking, custom aliases, and analytics to monitor link performance.',
      tags: ['URL Shortener', 'Link Tracking', 'Analytics'],
      link: 'https://t.ly/',
      hasStar: true,
    },
    {
      title: 'Kutt.it',
      description: 'An open-source URL shortener.',
      info: 'I want to develop a URL shortener that offers a user-friendly interface for creating short links. It will include analytics to track link performance and user engagement.',
      link: 'https://kutt.it',
      tags: ['URL Shortener', 'Link Tracking', 'Analytics'],
    },
    {
      title: '#TODO - QR Code Generator',
      description: 'A tool for generating QR codes.',
      info: 'This simple yet effective tool will generate QR codes for URLs and text, making it easy for you to share information quickly and efficiently through scannable codes.',
      tags: ['#TODO', 'QR Code', 'Sharing'],
      hidden: true, //need to get a good tool link
    },
    {
      title: 'Link Tracking Service',
      description: 'A service for tracking shortened links.',
      info: 'This service will enable you to create and track shortened links. I want to provide detailed analytics on link performance, including click rates and user demographics.',
      link: 'https://grabify.link/faq/features',
      hidden: true,
      tags: ['URL Shortener', 'Analytics', 'Link Tracking'],
    },
    {
      title: '#TODO Secret Chat Using WebSocket and Encryption',
      description: 'A real-time chat application.',
      info: 'I want to create a real-time chat application that uses WebSocket technology for instant messaging. All communications will be secured with end-to-end encryption, ensuring your privacy is protected.',
      tags: ['#TODO', 'Chat', 'WebSocket', 'Encryption'],
      hidden: true,
    },
    {
      title: 'Squoosh.app - Image Editor / Optimizer',
      description: 'A web app for optimizing images.',
      info: 'Squoosh allows you to compress and optimize images for the web, making them smaller without losing quality.',
      tags: ['Image Optimization', 'WEBP', 'JPEG', 'PNG'],
      link: 'https://squoosh.app',
      hasStar: true,
    },
    {
      title: 'SVGOMG - SVG Optimizer',
      description: 'A web-based tool for optimizing SVG files.',
      info: 'SVGOMG is powered by SVGO and allows you to clean up and optimize your SVG files for better performance.',
      tags: ['Image Optimization', 'SVG'],
      link: 'https://jakearchibald.github.io/svgomg/',
      hasStar: true,
    },
    {
      title: 'OSINT - People',
      description:
        'A powerful tool for collecting and analyzing open-source information from Telegram.',
      info: 'This tool helps you gather and analyze open-source intelligence from various online leaks and groups.',
      tags: ['Telegram', 'OSINT', 'People'],
      link: 'https://t.me/LightSearchBot/',
      hasStar: true,
      hidden: true,
    },
    {
      title: 'OSINT - Telegram',
      description:
        'A powerful tool for collecting and analyzing open-source information from Telegram.',
      info: 'This tool helps you gather and analyze open-source intelligence from various Telegram channels and groups.',
      tags: ['Telegram', 'OSINT', 'TG Messages'],
      link: 'https://t.me/LightFunStatsBot/',
      hasStar: true,
      hidden: true,
    },
  ]

  return (
    <div className="selection:text-white selection:bg-mantis-600 max-w-screen-md mx-auto">
      <div className="mb-4 text-center ">
        <Heading>My Favorite Online Tools</Heading>
        <p className="text-gray-700 font-normal my-8">
          I use these tools frequently and find them to be incredibly helpful in
          my daily work. Each tool has its own unique purpose and can be a
          valuable resource for specific tasks. Feel free to explore and check
          them out - I'm sure you'll find something that suits your needs. All
          credit and appreciation goes to the talented creators who developed
          these tools.
        </p>
      </div>
      <AccordionList
        items={items}
        secretKey={secretKey}
        pageThemeColor="#7dca6e"
      />
    </div>
  )
}

export default OnlineTools
