import React from "react";
import "./App.css";
import "./index.css";
import Index from "./pages";
import FromChecker from "./utils/sourceChecker";
// import Nav from "./parts/Nav/Nav";
// import LoadingPage from "./parts/LoadingPage/loading";
// import DOMCleanup from "./utils/DOMCleanup";

const App = () => {
  return (
    // <main className={`${pageLoaded ? "" : "loading"}`}>
    <>
      <FromChecker />
      {/* <DOMCleanup /> */}

      {/* <header>

        <FullScreenLogo />
      </header> */}
      <section className="text-center">
        {/* <h2 className="text-2xl mx-auto w-full">
          🚧 Page under construction... 👷‍♂️🔨
        </h2> */}
        <Index />
      </section>
      {/* </main> */}
    </>
  )
};

export default App;
