// import usePageLoad from "../../utils/usePageLoaded";
import "./Nav.css";
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Link, useLocation } from 'wouter'
// import { Logo, LogoTxt } from "../../images";
// import { useEventListener } from "../../hooks/useEventListener";
// import { LoadingContext, NavContext } from "../../pages";
// import useScreenDetector from "../../hooks/useScreenDetector";
// import Logo from "../../assets/images/MeirProfile.png";
// import { Route, Switch } from "wouter";
// import App from "../../App";
// import About from "../../pages/About";
// import Contact from "../../pages/Contact";
// // import DonationPage from "./pages/DonatePage/donate";
// import SharePage from "../../pages/SharePage/share";
// import TechnicalInformation from "../../pages/SharePage/TechnicalInformation";
// import WIPExperiments from '../../pages/WIPExperiments'
// import OnlineTools from '../../pages/OnlineTools'
// import Resume from '../../pages/Resume'
// import { ActiveLink } from "../ActiveLink/ActiveLink";

const MyNavLink = ({ to, text }) => {
  const [location] = useLocation()
  const isActive = location === to

  return (
    <li className="px-3 select-none" title={text}>
      <Link
        to={to}
        className={`hover:text-green-700 ${isActive ? 'text-green-500' : ''}`}
      >
        {text}
      </Link>
    </li>
  )
}
export const routes = () => {}
export const Nav = () => {
  return (
    <>
      <header className="max-w-screen-lg m-auto min-h-[10vh]">
        <nav className="px-8 py-4 flex flex-col md:flex-row justify-center sm:justify-between items-center max-w-screen-xl mx-auto">
          <Link to="/" title="Home">
            <div className="logo">
              <img
                src={'/assets/MeirLogo.webp'}
                alt="Meir Profile"
                className="h-12 w-12 rounded-full"
              />
            </div>
          </Link>
          <ul className="flex p-4 text-xl font-lucky tracking-wide mt-2 sm:mt-1 flex-wrap text-center justify-center">
            <MyNavLink to="/" text="Home" />
            <MyNavLink to="/about" text="About" />
            <MyNavLink to="/contact" text="Contact" />
            {/* <MyNavLink to="/wip" text="Experiments" /> */}
            <MyNavLink to="/tools" text="Tools" />
          </ul>
        </nav>
      </header>
      {/* <RouterProvider router={router} /> */}
      {/* <RouterProvider router={router} fallbackElement={<LoadingPage />} /> */}
      {/* <Router> */}
    </>
  )
}
