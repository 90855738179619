const Footer = () => {
  return (
    <footer className="min-h-[10vh] text-gray-600 body-font max-w-screen-lg flex items-end justify-center mx-auto">
      <div className="bg-gray-100">
        <div className="py-4 px-5 ">
          <p className="text-gray-500 text-sm">
            {`© ${new Date().getFullYear()} Meir K.`}
          </p>
        </div>
      </div>
    </footer>
  )
};
export default Footer;
