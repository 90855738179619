import React from 'react'
import AccordionList from '../components/AccordionList'

const WIPExperiments = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const secretKey = urlParams.get('secret')

  const items = [
    {
      title: 'Secret Chat Using WebSocket and Encryption',
      description: 'A real-time chat application.',
      info: 'I want to create a real-time chat application that uses WebSocket technology for instant messaging. All communications will be secured with end-to-end encryption, ensuring your privacy is protected.',
    },
    {
      title: 'URL Tracker (Part of the Shortener)',
      description: 'A tool for tracking URL clicks.',
      info: 'This tool will allow you to track and analyze URL clicks. I want to provide insights into user interactions, geographic data, and engagement metrics, helping you optimize link performance.',
      hidden: true,
    },
    {
      title: 'Connection Detail Checker',
      description: 'Logs user connection details.',
      info: 'With this feature, I want to log and display detailed information about users who connect to the site. It will enhance security by monitoring suspicious activities and providing insights into user behavior.',
    },
    {
      title: 'Blog Subdomain',
      description: 'A dedicated space for my blog.',
      info: 'I want to set up a dedicated subdomain for hosting my personal or project blog. It will allow for easy content management and serve as a focused platform for sharing articles, updates, and insights with you.',
    },
    {
      title: 'URL Shortener (Like Kutt)',
      description: 'An open-source URL shortener.',
      info: 'I want to develop a URL shortener that offers a user-friendly interface for creating short links. It will include analytics to track link performance and user engagement.',
      link: 'https://github.com/thedevs-network/kutt',
    },
    {
      title: 'QR Code Generator',
      description: 'A tool for generating QR codes.',
      info: 'This simple yet effective tool will generate QR codes for URLs and text, making it easy for you to share information quickly and efficiently through scannable codes.',
    },
    {
      title: 'WebRTC File Sharing/Chat (Snapdrop)',
      description: 'A peer-to-peer file sharing application.',
      info: 'I want to build a peer-to-peer file sharing application that leverages WebRTC technology, allowing you to share files and chat in real-time without needing a central server.',
      link: 'https://github.com/RobinLinus/snapdrop',
    },
    {
      title: 'Link Tracking Service',
      description: 'A service for tracking shortened links.',
      info: 'This service will enable you to create and track shortened links. I want to provide detailed analytics on link performance, including click rates and user demographics.',
      link: 'https://grabify.link/faq/features',
      hidden: true,
    },
    {
      title: 'Login/Signup Page',
      description: 'A secure authentication page.',
      info: 'I want to design a secure and user-friendly page for authentication and registration, ensuring a smooth onboarding experience while maintaining high security standards.',
    },
    {
      title: 'Quick Signup Options Integration',
      description: 'Integrates third-party authentication.',
      info: 'I want to integrate third-party authentication options like Google, Apple, and Auth0 to streamline the signup process, allowing you to register quickly using your existing accounts.',
    },
    {
      title: 'Portfolio of Personal Projects',
      description: 'Showcasing my personal projects.',
      info: 'Here, you will be able to explore a curated list of my personal projects and websites, giving you a clear view of my skills and experience.',
    },
    {
      title: 'Internationalization (i18n)',
      description: 'Support for multiple languages.',
      info: 'I want to implement internationalization features to support multiple languages on the website, enhancing accessibility and user experience for a global audience.',
    },
  ]

  return (
    <div className="selection:text-white selection:bg-coral_red-400 max-w-screen-md mx-auto">
      <div className="mb-4 text-center ">
        <h1 className="text-2xl font-bold mb-2">
          Things I want to try building
        </h1>
        <p className="text-gray-700">
          I have several projects I want to work on, based on existing projects
          and my own ideas. Each project has a specific purpose and can be
          useful for various tasks.
        </p>
      </div>
      <AccordionList
        items={items}
        secretKey={secretKey}
        pageThemeColor="#e0907b"
      />
    </div>
  )
}

export default WIPExperiments
