// import markdownContent from "../markdown/list.md";
// import MyMarkdownComponent from "../utils/markdown";
import React from "react";
import { ExistingWork } from "./ExistingWork";
// import ProjectList from "./projectsList";

const Index = () => {
  return (
    <div>
      {/* <ProjectList /> */}
      <ExistingWork />
    </div>
  );
};

export default Index;
